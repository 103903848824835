import { OidcMetadata } from "oidc-client-ts";

export const appName = "Svix";

export const supportEmail = "support@svix.com";

export const slackUrl = "https://www.svix.com/slack";
export const blogUrl = "https://www.svix.com/blog/";
export const pricingUrl = "https://www.svix.com/pricing/";
export const contactUrl = "https://www.svix.com/contact/";

export const homePage = "https://www.svix.com/";
export const adminDashboard = "https://dashboard.svix.com/";
export const forgotPassword = "https://www.svix.com/forgot-password";
export const docs = Object.freeze({
  apiRef: "https://api.svix.com/docs",
  index: "https://docs.svix.com/",
  appPortal: "https://docs.svix.com/management-ui",
  operationalWebhooks: "https://docs.svix.com/incoming-webhooks",
  quickstart: Object.freeze({
    index: "https://docs.svix.com/quickstart",
    application: "https://docs.svix.com/quickstart#creating-an-application",
    messages: "https://docs.svix.com/quickstart#send-a-message",
    endpoints: "https://docs.svix.com/quickstart#add-webhook-endpoints",
    eventTypes: "https://docs.svix.com/event-types",
  }),
  eventTypes: {
    index: "https://docs.svix.com/event-types",
    schema: "https://docs.svix.com/event-types#event-type-schema",
    openapiImport: "https://docs.svix.com/event-types#upload-openapi-specification",
  },
  integrations: {
    vercel: "https://vercel.com/integrations/svix",
    zapier: "https://docs.svix.com/integrations/zapier",
  },
  advanced: {
    eventCatalog: "https://docs.svix.com/event-types#publishing-your-event-catalog",
    transformations: {
      index: "https://docs.svix.com/transformations",
      templates: "https://docs.svix.com/transformations#transformation-templates",
    },
    retries: {
      disablingEndpoints: "https://docs.svix.com/retries#disabling-failing-endpoints",
    },
  },
});

export type SvixRegion = "us" | "eu" | "in" | "self-hosted";

export const products = Object.freeze({
  cli: "https://github.com/svix/svix-cli",
  play: "https://svix.com/play",
});

export const sentry = Object.freeze({
  appPortalDsn:
    "https://364e4aa2ed6e86d4ad17a97df33a3e7c@o4505744214065152.ingest.sentry.io/4505754848264192",
  dashboardDsn:
    "https://c8c637797fd82b2fc51c3b4ff807f3cb@o4505744214065152.ingest.sentry.io/4505754840989696",
});

interface EnvConfiguration {
  name: "local" | "dev" | "staging" | "prod" | "ee" | "staging-az-ee";
  getServerUrl: (region: SvixRegion) => string;
  adminDashboardUrl: string;
  playUrl: string;
  relayUrl: string;
  relayUrlIn: string;
  stripe: {
    publishableKey: string;
  };
  slackAppClientId: string;
  auth0: {
    audience: string;
    domain: string;
    mfa_domain: string;
    clientId: string;
  };
  oidc?: {
    authority: string;
    client_id: string;
    audience?: string;
    metadata: Partial<OidcMetadata>;
  };
}

function generateServerUrl(region: SvixRegion, baseUrl: string) {
  return `https://api.${region}.${baseUrl}`;
}

const SERVER_URL_KEY = "svixServerUrl";

// FIXME: hardcoded to test OIDC in EE staging. We need a better solution.
export const isOIDCEE =
  import.meta.env.MODE === "ee" &&
  window.location.hostname.endsWith(".staging-az.staging.svix.com");

export function getEnvConfiguration(): EnvConfiguration {
  const host = window.location.hostname;

  // Dashboard EE
  if (import.meta.env.MODE === "ee") {
    const relayUrl = `https://api.relay.svix.com`;
    const relayUrlIn = "https://play.svix.com/in/";
    const playUrl = "https://play.svix.com";

    // FIXME: hardcoded to test OIDC in EE staging. We need a better solution.
    // Staging AZ environment (with OIDC)
    if (window.location.hostname.endsWith(".staging-az.staging.svix.com")) {
      const oidcAuthority = "https://svix-customers.eu.auth0.com";
      return {
        name: "staging-az-ee",
        getServerUrl: (_region: SvixRegion) => {
          return "https://api.staging-az.staging.svix.com";
        },
        adminDashboardUrl: "https://app.staging-az.staging.svix.com/admin/",
        playUrl,
        relayUrl,
        relayUrlIn,
        stripe: {
          publishableKey: "UNSET",
        },
        slackAppClientId: "UNSET",
        auth0: {
          audience: "UNSET",
          domain: "UNSET",
          mfa_domain: "UNSET",
          clientId: "UNSET",
        },
        oidc: {
          authority: oidcAuthority,
          client_id: "HOhOdL2Zk2GnUO0fA66fUbsdTdy4I9Qw",
          audience: "https://api.staging.svix.com/api/v1",
          metadata: {
            userinfo_endpoint: oidcAuthority + "/userinfo",
            issuer: oidcAuthority,
            authorization_endpoint: oidcAuthority + "/authorize",
            token_endpoint: oidcAuthority + "/oauth/token",
            // For some reason, Auth0 doesn't publish their logout endpoint in their metadata,
            // so I have to configure it manually or the oidc lib fails when logging out.
            end_session_endpoint: oidcAuthority + "/v2/logout",
            jwks_uri: oidcAuthority + "/.well-known/jwks.json",
          },
        },
      };
    }

    return {
      name: "ee",
      getServerUrl: (_region: SvixRegion) => {
        // FIXME: A bit hacky. Ideally, we should set it at build time. This is easier for now.
        const url =
          import.meta.env.VITE_SERVER_URL || localStorage.getItem(SERVER_URL_KEY);
        if (!url) {
          throw new Error("Server URL not set");
        }
        return url;
      },
      adminDashboardUrl: `https://${host}/admin/`,
      playUrl,
      relayUrl,
      relayUrlIn,
      stripe: {
        publishableKey: "UNSET",
      },
      slackAppClientId: "UNSET",
      auth0: {
        audience: "UNSET",
        domain: "UNSET",
        mfa_domain: "UNSET",
        clientId: "UNSET",
      },
    };
  } else if (host.endsWith(".staging.svix.com")) {
    // Staging
    const relayUrl = `https://api.relay.svix.com`;
    const relayUrlIn = "https://play.svix.com/in/";
    const playUrl = "https://play.svix.com";
    return {
      name: "staging",
      getServerUrl: (region: SvixRegion) => generateServerUrl(region, "staging.svix.com"),
      adminDashboardUrl: "https://dashboard.staging.svix.com/",
      playUrl,
      relayUrl,
      relayUrlIn,
      stripe: {
        publishableKey:
          "pk_test_51IbNjlJjiiV6fvsoERNZar0cvNrOtOsJ1dPO1Rzp5FP3pKw3xLK3GcjwlkdJsiYHg1DZxaFfYW9HQ0tuhmBXsv7R0005ZBnHUv",
      },
      slackAppClientId: "1949106774466.2852266524400",
      auth0: {
        audience: "https://api.staging.svix.com/api/v1",
        domain: "svix-staging.eu.auth0.com",
        mfa_domain: "svix-staging.eu.auth0.com",
        clientId: "02KBJVzTnilSfxgPDkqSiNH3cOAJtaEM",
      },
    };
  } else if (host.endsWith(".svix.com")) {
    // Production
    const relayUrl = `https://api.relay.svix.com`;
    const relayUrlIn = `https://play.svix.com/in/`;
    const playUrl = `https://play.svix.com`;
    return {
      name: "prod",
      getServerUrl: (region: SvixRegion) => generateServerUrl(region, "svix.com"),
      adminDashboardUrl: "https://dashboard.svix.com/",
      playUrl,
      relayUrl,
      relayUrlIn,
      stripe: {
        publishableKey:
          "pk_live_51IbNjlJjiiV6fvsonGkDNP1T6hUFwsSy5tkfjj9k5kayBB5LAwJyULj2S1WQ5vlLvHsXsCj0lto5W9kIxgiGiN0200yXr5jdUc",
      },
      slackAppClientId: "1949106774466.2752505457121",
      auth0: {
        audience: "https://api.svix.com/api/v1",
        domain: "login.svix.com",
        mfa_domain: "svix.eu.auth0.com",
        clientId: "6TN9iBiSOZGKIgL0ULneC9q96BrrL9sv",
      },
    };
  } else if (host.endsWith(".svix.dev")) {
    // Development
    const relayUrl = `https://api.relay.svix.com`;
    const relayUrlIn = "https://play.svix.com/in/";
    const playUrl = "https://play.svix.com";
    return {
      name: "dev",
      getServerUrl: (region: SvixRegion) => generateServerUrl(region, "svix.dev"),
      adminDashboardUrl: "https://dashboard.svix.dev/",
      playUrl,
      relayUrl,
      relayUrlIn,
      stripe: {
        publishableKey:
          "pk_test_51JuiWTGZXm7MLG5IuSCkp1zhyXSftsoiuMKXVRreRSuZ6ImXIZDqQOirbGYA61982EjJYKftcWhkuj4Z9bqVxm24002xfVKohk",
      },
      slackAppClientId: "1949106774466.2760341728230",
      auth0: {
        audience: "https://api.svix.dev/api/v1",
        domain: "svix-dev.eu.auth0.com",
        mfa_domain: "svix-dev.eu.auth0.com",
        clientId: "H85osmNPHr64wNlplq0R6teRJlQKilMc",
      },
    };
  } else {
    // Local development
    const relayUrl = import.meta.env.VITE_RELAY_URL || `https://api.relay.svix.com`;
    const relayUrlIn = `${relayUrl}/api/v1/in/`;
    const playUrl = import.meta.env.VITE_PLAY_URL || "https://play.svix.com";
    return {
      name: "local",
      getServerUrl: (region: SvixRegion) =>
        region === "us"
          ? import.meta.env.VITE_SERVER_US_URL || import.meta.env.VITE_SERVER_URL
          : import.meta.env.VITE_SERVER_URL || generateServerUrl(region, "svix.dev"),
      adminDashboardUrl: "http://localhost:4000/",
      playUrl,
      relayUrl,
      relayUrlIn,
      stripe: {
        publishableKey:
          import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY ||
          "pk_test_51JuiWTGZXm7MLG5IuSCkp1zhyXSftsoiuMKXVRreRSuZ6ImXIZDqQOirbGYA61982EjJYKftcWhkuj4Z9bqVxm24002xfVKohk",
      },
      slackAppClientId: "1949106774466.2760341728230",
      auth0: {
        audience: `${import.meta.env.VITE_SERVER_URL}/api/v1`,
        domain: "svix-dev.eu.auth0.com",
        mfa_domain: "svix-dev.eu.auth0.com",
        clientId: "H85osmNPHr64wNlplq0R6teRJlQKilMc",
      },
    };
  }
}

export const envConfig = getEnvConfiguration();

const slackAppRegisterBaseUrl = `https://slack.com/oauth/v2/authorize?client_id=${envConfig.slackAppClientId}&scope=chat:write,incoming-webhook,links:write&user_scope=`;

export const slackAppPortalOauthClientId = "1949106774466.5559259320660";

export const getSlackAppPortalOauthAuthorizeUrl = (redirectUri: string) => {
  return `https://slack.com/oauth/v2/authorize?client_id=${slackAppPortalOauthClientId}&scope=incoming-webhook&redirect_uri=${redirectUri}`;
};

export const discordAppPortalOauthClientId = "1162020107447435275";

export const getDiscordAppPortalOauthAuthorizeUrl = (redirectUri: string) => {
  return `https://discord.com/api/oauth2/authorize?client_id=${discordAppPortalOauthClientId}&redirect_uri=${redirectUri}&response_type=code&scope=webhook.incoming`;
};

export const getInngestAppPortalConnectionUrl = (
  redirectUri: string,
  customerDomain: string
) => {
  const encodedCustomerDomain = encodeURIComponent(customerDomain);
  return `https://app.inngest.com/intent/create-webhook?domain=${encodedCustomerDomain}&redirect_uri=${redirectUri}`;
};

export function getSlackRedirectUri() {
  return `${window.location.protocol}//${window.location.host}/settings/slack/register`;
}

export function getSlackAppRegistrationUri() {
  return `${slackAppRegisterBaseUrl}&redirect_uri=${getSlackRedirectUri()}`;
}

const HubspotClientId = "8ac27ef7-4a10-48fa-92f5-527d8d2270d2";

// FIXME: not configurable at the moment, so we ask for all CRM-related write scopes, likely the only
// ones a customer will use in a webhook connection.
const hubspotScopes = [
  "crm.objects.contacts.write",
  "crm.lists.write",
  "crm.objects.companies.write",
  "crm.objects.custom.write",
  "crm.objects.deals.write",
  "crm.objects.quotes.write",
  "crm.objects.marketing_events.write",
]
  .map((scope) => encodeURIComponent(scope))
  .join("%20");

export const getHubspotAppPortalOauthAuthorizeUrl = (redirectUri: string) => {
  return `https://app.hubspot.com/oauth/authorize?client_id=${HubspotClientId}&redirect_uri=${redirectUri}&response_type=code&scope=${hubspotScopes}`;
};

// FIXME: A bit hacky. Ideally, we should set it at build time. This is easier for now.
export const setServerUrl = (url: string) => {
  localStorage.setItem(SERVER_URL_KEY, url);
};

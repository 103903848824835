import { useEffect } from "react";
import {
  Flex,
  FlexProps,
  IconButton,
  List,
  useColorModeValue,
  useConst,
} from "@chakra-ui/react";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { useLocation, Link, useHistory, useRouteMatch } from "react-router-dom";

import { setSearch, useSearch } from "@svix/common/hooks/search";
import { inIframe } from "@svix/common/utils";

import { routeResolver } from "../App";

interface LinkTabProps extends FlexProps {
  to: string;
}

function LinkTab(props: LinkTabProps) {
  const { to, ...rest } = props;
  const match = useRouteMatch(to);
  const matchColor = useColorModeValue("navigation.accent", "navigation.accent");

  return (
    <Flex
      as={Link}
      borderBottom="2px solid"
      borderBottomColor={match ? matchColor : "transparent"}
      to={to}
      color={match ? matchColor : undefined}
      py={3}
      px={4}
      transition="0.1s color ease-in, 0.1s border-color ease-in"
      {...rest}
    />
  );
}

export default function NavigationTabs() {
  const location = useLocation();
  const history = useHistory();
  const initialHistoryLength = useConst(history.length);
  const value = location.pathname.startsWith(routeResolver.getRoute("endpoints")) ? 0 : 1;
  const page = Number(useSearch("page") ?? history.length);

  useEffect(() => {
    if (history.action === "PUSH" || history.action === "REPLACE") {
      setSearch("page", history.length);
    }
  }, [history.action, history.length, location]);

  return (
    <Flex
      value={value}
      colorScheme="brand"
      position="sticky"
      top={0}
      bgColor="background.primary"
      left={0}
      right={0}
      zIndex={1}
      px={8}
    >
      <List display="flex" flexDir="row">
        {inIframe() && (
          <Flex alignItems="center">
            <IconButton
              ml={-2}
              size="sm"
              aria-label="Go back"
              variant="ghost"
              isDisabled={page === initialHistoryLength}
              onClick={history.goBack}
            >
              <ArrowBack style={{ fontSize: 16 }} />
            </IconButton>
            <IconButton
              mx={2}
              size="sm"
              aria-label="Go forward"
              variant="ghost"
              onClick={history.goForward}
              isDisabled={page >= history.length}
            >
              <ArrowForward style={{ fontSize: 16 }} />
            </IconButton>
          </Flex>
        )}
        <LinkTab to={routeResolver.getRoute("endpoints")}>Endpoints</LinkTab>
        <LinkTab to={routeResolver.getRoute("event-types")}>Event Catalog</LinkTab>
        <LinkTab to={routeResolver.getRoute("messages")}>Logs</LinkTab>
        <LinkTab to={routeResolver.getRoute("activity")}>Activity</LinkTab>
      </List>
    </Flex>
  );
}
